var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"colori"}},[_c('el-row',{staticClass:"titolo",attrs:{"span":24}},[_c('h3',[_vm._v(_vm._s(_vm._f("upperCase")(_vm.$t("message.colori"))))])]),_c('el-row',{staticClass:"radio-button",attrs:{"span":24}},[_c('el-row',{attrs:{"span":24}},[_c('h4',[_vm._v(_vm._s(_vm.$t("message.Finitura")))])]),_c('el-row',{attrs:{"span":24}},[_c('el-radio-group',{staticClass:"full-width-double-radio-group",attrs:{"id":"full-width-double-radio-group-finitura"},model:{value:(_vm.finitura),callback:function ($$v) {_vm.finitura=$$v},expression:"finitura"}},[_c('el-radio-button',{attrs:{"label":"S"}},[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.$t("message.lucido")))+" ")]),(!_vm.$store.state.affogatiEnabled)?_c('el-radio-button',{attrs:{"label":"M"}},[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.$t("message.opaco")))+" ")]):_vm._e(),_c('el-radio-button',{attrs:{"label":"N"}},[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.$t("message.Naturale")))+" ")])],1)],1)],1),(_vm.$store.state.affogatiEnabled)?_c('el-row',{staticClass:"radio-button",attrs:{"span":24}},[_c('el-row',{attrs:{"span":24}},[_c('h4',[_vm._v(_vm._s(_vm.$t("message.Tinta_unita")))])]),_c('el-row',{attrs:{"span":24}},[_c('el-radio-group',{staticClass:"full-width-double-radio-group",model:{value:(_vm.tintaUnita),callback:function ($$v) {_vm.tintaUnita=$$v},expression:"tintaUnita"}},[_c('el-radio-button',{attrs:{"label":true}},[_vm._v(" "+_vm._s(_vm._f("upperCase")(_vm.$t("message.Si")))+" ")]),_c('el-radio-button',{attrs:{"label":false}},[_vm._v(" "+_vm._s(_vm._f("upperCase")(_vm.$t("message.NO")))+" ")])],1)],1)],1):_vm._e(),(!_vm.isGuest && _vm.isSuperUser == true)?_c('div',[_c('KitDialog')],1):_vm._e(),_c('el-row',{attrs:{"span":24}},[_c('el-input',{staticClass:"cerca",attrs:{"suffix-icon":"el-icon-search","placeholder":_vm.$t('message.Cerca') +
    " " + (_vm.filteredColors.length) + " " +
    _vm.$t('message.colori'),"size":"small","clearable":""},model:{value:(_vm.findColor),callback:function ($$v) {_vm.findColor=$$v},expression:"findColor"}}),_c('el-select',{staticClass:"categories",attrs:{"multiple":"","collapse-tags":"","placeholder":_vm.$t('message.Scegli_categorie'),"size":"small"},model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}},_vm._l((_vm.categories),function(c,i){return _c('el-option',{key:i,attrs:{"value":c}},[_vm._v(" "+_vm._s(c)+" ")])}),1),_c('el-row',{staticClass:"color-filter-wrapper"},_vm._l((_vm.filtriColori),function(filter,index){return _c('img',{key:index,staticClass:"color-filter",class:{ activeFilter: filter == _vm.filtroColori },attrs:{"src":'/images/icone/circle' + filter.nome + '.svg',"alt":""},on:{"click":function($event){_vm.filtroColori = filter}}})}),0),_c('el-row',{staticClass:"no-filter-container"},[_c('el-button',{staticClass:"button-all-colors",class:{ 'is-active': _vm.findEcoColor == '' },attrs:{"type":"default"},on:{"click":function($event){(_vm.filtroColori = ''), (_vm.findEcoColor = '')}}},[_vm._v(_vm._s(_vm._f("upperCase")(_vm.$t("message.Tutti"))))]),_c('el-button',{staticClass:"butto-eco",class:{ active: _vm.findEcoColor == 'EP' },attrs:{"type":"default"},on:{"click":function($event){(_vm.findEcoColor = 'EP'), (_vm.filtroColori = '')}}},[_c('Leaf'),_c('span',[_vm._v("ECO")])],1)],1),_c('el-col',{staticClass:"img-colors"},_vm._l((_vm.filteredColors),function(c){return _c('el-col',{key:c.ItemCode,staticClass:"color-item",class:{
    notActive:
      (_vm.frontModel == 'OV-823C' || _vm.frontModel == 'OV-834C') &&
      c.Category == 'MULTISTRATO',
  },attrs:{"span":12},nativeOn:{"click":function($event){((_vm.frontModel == 'OV-823C' || _vm.frontModel == 'OV-834C') &&
      c.Category == 'MULTISTRATO') || (c.GiacIta < 1 && !c.ShipDateIta)
      ? null
      : _vm.setColore(c)}}},[((_vm.frontModel == 'OV-823C' || _vm.frontModel == 'OV-834C') &&
    c.Category == 'MULTISTRATO'
    )?_c('span',{staticClass:"not-allowed-span"},[_vm._v(_vm._s(_vm.$t("message.Non_disponibile_per_questo_modello")))]):_vm._e(),(c.GiacIta < 1 && !c.ShipDateIta)?_c('span',{staticClass:"color-finished"},[_vm._v(_vm._s(_vm.$t('message.Colore_esaurito')))]):_vm._e(),_c('span',{class:{
    not_checked:
      _vm.$store.state.configurator.colore.U_THE_COLORE != c.U_THE_COLORE,
  },attrs:{"id":"checkmark"}}),_c('MatImage',{staticClass:"color-image",class:{
    notActive:
      (_vm.frontModel == 'OV-823C' || _vm.frontModel == 'OV-834C') &&
      c.Category == 'MULTISTRATO',
  },attrs:{"lazy-src":c.imageUrl}}),_c('span',{staticClass:"color-name",class:{
    notActive:
      (_vm.frontModel == 'OV-823C' || _vm.frontModel == 'OV-834C') &&
      c.Category == 'MULTISTRATO',
  }},[_vm._v(_vm._s(c.U_THE_COLORE.replace("PL", "")))])],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }